<template>
  <div>
    <!-- Start Hero Section ----------------------->
    <div class="pa-0">
      <v-container fluid class="pa-0">
        <!-- Desktop -->
        <!-- Left content -->
        <v-col
          cols="12"
          class="Center hero pa-0"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-img
            src="../assets/img-edited7.svg"
            class="d-flex align-center justify-center"
            height="100vh"
          >
            <div>
              <div class="my-10 h1-hero Center">RILEY PARADA</div>
              <div class="my-10 h3-hero light mb-5 Center">PRODUCT MANAGER</div>
              <div class="d-flex Center">
                <v-btn
                  class="my-15 h4"
                  color="#ff6b6b"
                  dark
                  x-large
                  depressed
                  to="/Contact"
                >
                  <v-icon class="mr-3">mdi-hand-wave</v-icon> Get in Touch
                </v-btn>
              </div>
            </div>
          </v-img>
        </v-col>

        <!-- Mobile-->
        <template v-else>
          <v-col cols="12" class="Center hero pa-0">
            <v-img
              src="../assets/img-edited7.svg"
              class="d-flex align-center justify-center"
              height="100vh"
            >
              <div class="mt-10 h2-hero d-flex text-center">RILEY PARADA</div>
              <div class="mb-10 h3-hero mb-5 Center text-center">
                A 25 year old Product Manager
              </div>
              <div class="h4 Center">204-619-1229</div>
              <div class="pt-4 h4 mb-5 Center">rileyleitao@gmail.com</div>

              <v-btn
                class="mt-5 h4 ma-15 d-flex Center"
                color="#ff6b6b"
                dark
                x-large
                depressed
                to="/Contact"
              >
                <v-icon class="mr-3">mdi-hand-wave</v-icon> Get in Touch
              </v-btn>
            </v-img>
          </v-col>
        </template>
      </v-container>
      <!-- <v-row>
        <v-col cols="6">
          <div>
            <span class="h2-white"> A 25 year old </span
            ><span class="h2"><mark>Product Manager</mark></span
            ><span class="h2 coolBlue">.</span>
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <span class="h5-light"> rileyleitao@gmail.com </span>
          </div>
          <div>
            <span class="h5-light"> +1-(204)-619-1229 </span>
          </div>
        </v-col>
      </v-row>
    </v-container> -->
    </div>
    <!-- End Hero Section ----------------------->
    <v-container fluid class="mt-15">
      <v-row>
        <v-col cols="12">
          <div>
            <div class="d-flex Center h2 HeaderUnderline mt-15 mb-10">
              Products
            </div>
            <!-- <div class="d-flex justify-center h3">
              Technical. Management. Leadership.
            </div> -->
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row class="flex-column">
              <v-col cols="12" class="py-15">
          <v-card
            class="mx-auto cursor"
            max-width="600"
            @click="$router.push({ name: 'Parallel' })"
          >
            <v-img src="https://firebasestorage.googleapis.com/v0/b/edith-79deb.appspot.com/o/lightLogo.svg?alt=media&token=2f1bf896-96c1-4ce3-8e5a-29fc0805af15" max-height="300px"></v-img>

            <v-card-title> Edith Medical </v-card-title>

            <v-card-subtitle> Focus on Patients, Not Paperwork </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="blue"
                outlined
                text
                target="_blank"
                href="https://www.edithmedical.com"
              >
                Visit
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="show1 = !show1">
                <v-icon>{{
                  show1 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show1">
                <v-divider></v-divider>

                <v-card-text>
                  Edith Medical is a medical tech startup specializing in bringing advancements to clinics through proprietary software powered by artifical intelligence. Edith Medical assists healthcare providers by documenting patient encounters, transcribing dictated notes, and handling administrative tasks, allowing providers to focus more on patient care.
                <br><br/>
                Edith Medical's services are aimed at improving the efficiency and accuracy of medical documentation, which can lead to better patient care and outcomes. 
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>

        <v-col cols="12" class="py-15">
          <v-card
            class="mx-auto cursor"
            max-width="600"
            @click="$router.push({ name: 'Parallel' })"
          >
            <v-img src="../assets/parallel.png" max-height="300px"></v-img>

            <v-card-title> Parallel </v-card-title>

            <v-card-subtitle> Find what fits you </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="blue"
                outlined
                text
                target="_blank"
                href="https://www.joinparallel.io/about"
              >
                Visit
              </v-btn>
              <!-- TODO Comment this back in to expand on the projects -->
              <!-- <v-btn color="blue lighten-2" text to="about"> Learn More </v-btn> -->

              <v-spacer></v-spacer>

              <v-btn icon @click="show1 = !show1">
                <v-icon>{{
                  show1 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show1">
                <v-divider></v-divider>

                <v-card-text>
                  Parallel was built to solve the multi-billion dollar issue of
                  clothing returns due to poor fitting clothes. We sought to do
                  this by leveraging a custom built AI within a peer to peer
                  social commerce network. <br /><br />Click to find out the
                  back story behind why and how it was built.
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="12" class="py-15">
          <v-card
            class="mx-auto cursor"
            outlined
            max-width="600"
            @click="$router.push({ name: 'Fireside' })"
          >
            <v-img
              src="../assets/FiresideCornerLogo.png"
              max-height="300px"
            ></v-img>

            <v-card-title> Fireside Corner </v-card-title>

            <v-card-subtitle>
              Wood stove and Fireplace eCommerce Store
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="blue"
                outlined
                text
                target="_blank"
                href="https://www.firesidecorner.ca"
              >
                Visit
              </v-btn>
              <!-- TODO Comment this back in to expand on the projects -->
              <!-- <v-btn color="blue lighten-2" text to="about"> Learn More </v-btn> -->

              <v-spacer></v-spacer>

              <v-btn icon @click="show1 = !show1">
                <v-icon>{{
                  show1 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show1">
                <v-divider></v-divider>

                <v-card-text>
                  Fireside Corner is a business I built and own that sells
                  fireplaces, wood stoves and accessories through an eCommerce
                  website, in person, and through social channels such as
                  Facebook and Kijiji.

                  <br /><br />Click to find out the back story behind why and
                  how it was built.
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col cols="12" class="py-15">
          <v-card
            class="mx-auto cursor mb-15"
            max-width="600"
            @click="$router.push({ name: 'KelvinElectric' })"
          >
            <v-img
              src="../assets/KelvinElectric_Fulllogo.svg"
              max-height="300px"
            ></v-img>

            <v-card-title> Kelvin Electric </v-card-title>

            <v-card-subtitle> Kelvin Electric's Website Build </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="blue"
                outlined
                text
                target="_blank"
                href="https://www.kelvinelectricmb.ca/"
              >
                Visit
              </v-btn>
              <!-- TODO Comment this back in to expand on the projects -->
              <!-- <v-btn color="blue lighten-2" text to="about"> Learn More </v-btn> -->

              <v-spacer></v-spacer>

              <v-btn icon @click="show2 = !show2">
                <v-icon>{{
                  show2 ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show2">
                <v-divider></v-divider>

                <v-card-text>
                  Kelvin Electric was a project to be a local electrical
                  company's first website and online presence. <br /><br />Click
                  to find out the back story behind why and how it was built.
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card
            class="mx-auto cursor"
            max-width="600"
            @click="$router.push({ name: 'HomeAssessment' })"
          >
            <v-img
              src="../assets/wpgpropertyassessment.png"
              max-height="300px"
            ></v-img>

            <v-card-title> Winnipeg Home Assessment Tool </v-card-title>

            <v-card-subtitle> Find the value of your home </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color="blue"
                outlined
                text
                target="_blank"
                href="https://winnipeghomeassessment.firebaseapp.com/"
              >
                Visit
              </v-btn>
              <!-- TODO Comment this back in to expand on the projects -->
              <!-- <v-btn color="blue lighten-2" text to="about"> Learn More </v-btn> -->
              <v-tooltip bottom>
                <template>
                  <v-btn
                    depressed
                    href="https://github.com/rileyleitao/WinnipegPropertyEvaluations"
                  >
                    <v-icon color="black">fab fa-github</v-icon>
                  </v-btn>
                </template>
                <span>View on Github</span>
              </v-tooltip>

              <v-spacer></v-spacer>

              <v-btn icon @click="show = !show">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                  The Winnipeg Home Assessment tool was built by request from a
                  local home developer. <br /><br />Click to find out the back
                  story behind why and how it was built.
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <ContactForm></ContactForm>
    </template>
  </div>
</template>
<script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>

<script>
import ContactForm from "/src/components/ContactForm.vue";

export default {
  components: {
    ContactForm,
  },

  data: () => ({
    show: true,
    show1: true,
    show2: true,
  }),
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@300;400;500;600;700;800&display=swap");
.h1 {
  font-size: 4.5em;
  color: #ffffff;
  font-family: "Poppins", "sans-serif";
  font-weight: 600;
  letter-spacing: 6px;
}
.h2 {
  font-size: 3em;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h3 {
  font-size: 1.6em;
  color: #ffffff;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
  letter-spacing: 1.5px;
}
.h4 {
  font-size: 1em;
  color: #ffffff;

  font-family: "Raleway", "sans-serif";
  font-weight: 500;
}
.h5 {
  font-size: 1em;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-light {
  font-size: 1em;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}

.white {
  color: #ffffff;
}
.blue {
  color: #1a535c;
}
.coral {
  color: #ff6b6b;
}

/* .site-background {
  background-color: #edf6f9;
} */
/* .spacer {
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.layer1 {
  background-image: url("../assets/layer1.svg");
} */

.cursor:hover {
  cursor: pointer;
}

.hero {
  background-color: #141b25;
}
.full-width {
  width: 100% !important;
}

.h2-white {
  font-size: 3em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}
mark {
  background-color: rgba(255, 107, 107, 0.3);
  color: #ff6b6b;
  margin-top: 50%;
  height: 0.5em;
  top: 50%;
  z-index: -1;
  border-radius: 1em 0 1em 0;
}

.coolBlue {
  color: #4ecdc4;
}

.darkBlue {
  color: #1a535c;
}

.Center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* #ffe66d
  Yellow
  #ff6b6b
  Salmon
  #edf6f9
  blueish
  white
  #4ecdc4
  Teal
  #1a535c */
.HeaderUnderline:before {
  content: "";
  width: 15vw;
  padding-top: 3px;
  margin-right: 15px;
  border-bottom: 2px solid #ff6b6b;
}
.HeaderUnderline:after {
  content: "";
  width: 15vw;
  padding-top: 3px;
  margin-left: 15px;
  border-bottom: 2px solid #ff6b6b;
}
.dot {
  height: 130px;
  width: 130px;
  background-color: #ff6b6b;
  border-radius: 50%;
  display: flex;
}
.CenterIcon {
  display: flex;
}
.empty {
  color: transparent;
  text-decoration: none;
}
.Section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@font-face {
  font-family: "Deco";
  src: url("MeshedDisplay-SemiBold.ttf");
  font-weight: normal;
}
.h1-hero {
  font-size: 10em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
  letter-spacing: 30px;
}
.h2-hero {
  font-size: 5em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
  letter-spacing: 15px;
}
.h3-hero {
  font-size: 2em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
</style>