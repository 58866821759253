import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import VueRouter from "vue-router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "/src/assets/main.css";
import VueGtag from "vue-gtag";
// import * as firebase from "firebase";
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyDSvAIO_ob9FwsIZE2JJUgkKG9-aJEJP44",
//   authDomain: "rileywebsite-c815f.firebaseapp.com",
//   databaseURL: "https://rileywebsite-c815f-default-rtdb.firebaseio.com",
//   projectId: "rileywebsite-c815f",
//   storageBucket: "rileywebsite-c815f.appspot.com",
//   messagingSenderId: "639199802437",
//   appId: "1:639199802437:web:bf7607e57fce4ece441f2c",
//   measurementId: "G-JRZS17HK1B"
// };
// const analytics = getAnalytics(app);
// const app = initializeApp(firebaseConfig);
// console.log(app)
// const router = new VueRouter({
//   routes: [
//     { name: "Home", path: "/", component: Home },
//     { name: "About", path: "/about", component: About },
//     { name: "Contact", path: "/contact", component: Contact },
//     { name: "Ideas", path: "/ideas", component: Ideas },
//     {
//       name: "KelvinElectric",
//       path: "/kelvinelectric",
//       component: KelvinElectric,
//     },
//     {
//       name: "HomeAssessment",
//       path: "/homeassessment",
//       component: HomeAssessment,
//     },
//     { name: "Parallel", path: "/parallel", component: Parallel },
//     { name: "Signup", path: "/signup", component: Signup },
//   ],
// });

Vue.use(
  VueGtag,
  {
    config: { id: "G-JRZS17HK1B" },
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
