<template>
  <div class="site-background">
    <v-container class="content-area">
      <v-row class="SplashHeaderBackground">
        <v-col cols="12">
          <v-img
            src="https://firebasestorage.googleapis.com/v0/b/edith-79deb.appspot.com/o/Edithmedical.png?alt=media&token=3aa2e70e-8086-400b-9605-84191488c0af"
            alt="Edith Medical"
            class="rounded-xl"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="h3 HeaderUnderline">What is Edith Medical?</p>
          <p class="h5">
            Edith Medical is a medical tech startup specializing in bringing advancements to clinics through proprietary software powered by artifical intelligence. 
            Edith Medical assists healthcare providers by documenting patient encounters, transcribing dictated notes, and handling administrative tasks, allowing providers to focus more on patient care.

            Edith Medical's services are aimed at improving the efficiency and accuracy of medical documentation, which can lead to better patient care and outcomes.
          </p>
          <p class="h3 HeaderUnderline">The Background</p>

          <p class="h5">
            Edith Medical was brought to life through necessity. A local clinic approached myself and a partner with the task at assisting them improve efficiencies in their clinic. Initially I was unaware at how much time doctors spend doing administrative tasks such as note taking on a daily basis.
            This presents an interesting and obvious issue of accuracy and efficiency within the clinics. Doctors have to recall everything that was discussed during the day and record it at the end of the day which presents issues with accuracy. 
            Additionally, its a cumbersome task to spend sometimes up to 4 hours wrapping up notes from the day. This was the challenge at hand.
          <p class="h5">
            Upon doing more discovery of this problem, we found that many other doctors had a similar problem. So we got started trying to come up with a scalable solution. This is how Edith Medical was born.
          </p>
                      <div class="h3 d-flex justify-center mb-10">More Coming Soon!</div>

        </v-col>
      </v-row>
      <v-row>
        <v-col class="Section">
          <div class="align-items-centers">
            <div class="h3 d-flex justify-center mb-10">Let's Connect</div>
            <div class="h5 d-flex justify-center mb-10 centerText">
              Feel free to reach out.<br />
              I'm always open to discussing work, side projects, opportunities,
              or just to chat.<br />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <router-link to="Contact" class="empty">
          <div class="dot d-flex justify-center cursor">
            <v-icon class="CenterIcon" x-large dark>mail</v-icon>
          </div>
        </router-link>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Parallel",
  // data() {},
};
</script>
<style scoped>
.content-area {
  max-width: 800px;
}
.spacer {
  margin-bottom: 48px;
}
.h1 {
  font-size: 4.5em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
}
.h2 {
  font-size: 3em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}
.h3 {
  font-size: 2em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h5 {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-light {
  font-size: 1em;
  color: #edf6f9c4;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-bold {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}

.site-background {
  background-color: #edf6f9;
}
.dot {
  height: 130px;
  width: 130px;
  background-color: #ff6b6b;
  border-radius: 50%;
  display: flex;
}
.empty {
  color: transparent;
  text-decoration: none;
}

.cursor:hover {
  cursor: pointer;
}

.hero {
  background-color: black;
  min-height: 100vh;
}
.h2-white {
  font-size: 3em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}

.coolBlue {
  color: #4ecdc4;
}

.darkBlue {
  color: #1a535c;
}

.numbering {
  color: #ff6b6b;
  font-weight: 800;
}

.HeaderUnderline:after {
  content: "";
  display: block;
  width: 32px;
  padding-top: 3px;
  border-bottom: 2px solid #ff6b6b;
}
.SplashHeaderBackground {
  background-color: #edf6f9;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Section {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>

