
<template>
  <v-app id="inspire">
    <v-navigation-drawer color="#141b25" dark v-model="drawer" app>
      <!-- <v-list-item>
        <v-list-item-content> -->
      <!-- <v-list-item-title class="text-h6"> Riley Parada </v-list-item-title> -->
      <!-- <v-list-item-subtitle> Personal Site </v-list-item-subtitle> -->
      <!-- </v-list-item-content>
      </v-list-item> -->

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar flat app color="#141b25">
      <v-app-bar-nav-icon dark @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer>
        <!-- <v-toolbar-title dark class="centerText h3-app"
          >Riley Parada</v-toolbar-title
        > -->
      </v-spacer>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <div v-if="$vuetify.breakpoint.mdAndUp" class="cursor">
      <div id="mouse-circle"></div>
      <div id="mouse-circle-inner"></div>
    </div>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    items: [
      { title: "Products", icon: "mdi-view-dashboard", to: "/" },
      { title: "About", icon: "mdi-help-box", to: "/about" },
      { title: "Edith Medical", icon: "mdi-medical-bag", to: "/EdithMedical" },
      { title: "Parallel", icon: "mdi-math-norm-box", to: "/Parallel" },
            {
        title: "Kelvin Electric",
        icon: "mdi-lightning-bolt-circle",
        to: "/KelvinElectric",
      },
      {
        title: "Assessment Tool",
        icon: "mdi-home-analytics",
        to: "/HomeAssessment",
      },
      {
        title: "Contact",
        icon: "mdi-email",
        to: "/Contact",
      },

      // { title: "Sign in", icon: "mdi-image", to: "/signup" },
    ],
  }),
};
// Cursor
document.addEventListener("DOMContentLoaded", () => {
  let mousePosX = 0,
    mousePosY = 0,
    mouseCircle = document.getElementById("mouse-circle"),
    mouseCircleInner = document.getElementById("mouse-circle-inner");

  document.onmousemove = (e) => {
    mousePosX = e.pageX;
    mousePosY = e.pageY;
  };

  let delay = 6,
    revisedMousePosX = 0,
    revisedMousePosY = 0;

  function delayMouseFollow() {
    requestAnimationFrame(delayMouseFollow);

    revisedMousePosX += (mousePosX - revisedMousePosX) / delay;
    revisedMousePosY += (mousePosY - revisedMousePosY) / delay;

    mouseCircle.style.top = revisedMousePosY + "px";
    mouseCircle.style.left = revisedMousePosX + "px";
    mouseCircleInner.style.top = mousePosY + "px";
    mouseCircleInner.style.left = mousePosX + "px";
  }
  delayMouseFollow();
});
</script>

<style>
.site-background {
  background-color: #f7fff7;
}
.sidebar {
  background-color: #141b25;
}
.navbar {
  background-color: #f88888;
}
.centerText {
  text-align: center;
}
body {
  cursor: none;
}

.h1 {
  font-size: 4.5em;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
}
.h2 {
  font-size: 3em;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h3 {
  font-size: 2em;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h3-app {
  font-size: 2em;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h4 {
  font-size: 1em;
  font-family: "Raleway", "sans-serif";
  font-weight: 500;
}
.h5 {
  font-size: 1em;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-light {
  font-size: 1em;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
/* .white{
  color: #ffffff;
}
.blue{
  color: #1a535c;
}
.coral{
  color: #ff6b6b
} */

/* Cursor */
#mouse-circle {
  position: absolute;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  mix-blend-mode: difference;
  pointer-events: none;
  user-select: none;
  z-index: 20;
}

#mouse-circle-inner {
  position: absolute;
  width: 7px;
  height: 7px;
  margin: -3px 0 0 -3px;
  background-color: #ffffff;
  border-radius: 25px;
  pointer-events: none;
  mix-blend-mode: difference;
  pointer-events: none;
  user-select: none;
  z-index: 20;
}
</style>