<template>
  <div class="site-background">
    <v-container class="content-area">
      <v-row class="SplashHeaderBackground">
        <v-col cols="12">
          <v-img
            src="../assets/kelvin.png"
            alt="Kelvin Project"
            class="rounded-xl"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="h3 HeaderUnderline">What is Kelvin Electric?</p>
          <p class="h5">
            Kelvin Electric is a electrical company based in the Winnipeg Metro
            Area. Kelvin Electric primarily focuses on providing commercial
            services, specifically for the installation of high speed fiber
            optic cables.
          </p>
          <p class="h3 HeaderUnderline">The Background</p>

          <p class="h5">
            The Kelvin Electric website was built to provide the company with
            it's first website to provide an online presence for the company
            with a simple layout, with just enough information.
          </p>
          <p class="h5">
            Instead of building a product on top of flawed size guides, I
            suggested we instead use the body measurement technology as the
            common denominator and leverage peer to peer data to inform users on
            what would fit. This is how Parallel was born.
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col>
          <p class="h3 HeaderUnderline">What was your Role?</p>
          <p class="h5">
            As the sole Software Developer, here were some of my
            responsibilities:
          </p>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Develop the website in a
            quick and efficient manner.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Work with the owner on
            populating the website with relevant, useful information.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Designing the style of the
            website with approval from the client.
          </div>
        </v-col>
      </v-row>
      <v-row class="Section">
        <v-col>
          <div class="align-items-centers">
            <div class="h3 d-flex justify-center mb-10">Let's Connect</div>
            <div class="h5 d-flex justify-center mb-10 centerText">
              Feel free to reach out.<br />
              I'm always open to discussing work, side projects, opportunities,
              or just to chat.<br />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <router-link to="Contact" class="empty">
          <div class="dot d-flex justify-center cursor">
            <v-icon class="CenterIcon" x-large dark>mail</v-icon>
          </div>
        </router-link>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "KelvinElectric",
  // data() {},
};
</script>
<style scoped>
.content-area {
  max-width: 800px;
}
.spacer {
  margin-bottom: 48px;
}
.h1 {
  font-size: 4.5em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
}
.h2 {
  font-size: 3em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}
.h3 {
  font-size: 2em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h5 {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-light {
  font-size: 1em;
  color: #edf6f9c4;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-bold {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}

.site-background {
  background-color: #edf6f9;
}
.dot {
  height: 130px;
  width: 130px;
  background-color: #ff6b6b;
  border-radius: 50%;
  display: flex;
}
.empty {
  color: transparent;
  text-decoration: none;
}

.cursor:hover {
  cursor: pointer;
}

.hero {
  background-color: black;
  min-height: 100vh;
}
.h2-white {
  font-size: 3em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}

.coolBlue {
  color: #4ecdc4;
}

.darkBlue {
  color: #1a535c;
}

.numbering {
  color: #ff6b6b;
  font-weight: 800;
}

.HeaderUnderline:after {
  content: "";
  display: block;
  width: 32px;
  padding-top: 3px;
  border-bottom: 2px solid #ff6b6b;
}
.SplashHeaderBackground {
  background-color: #edf6f9;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Section {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>

