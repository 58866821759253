<template>
  <div class="site-background">
    <v-container class="content-area">
      <v-row class="SplashHeaderBackground">
        <v-col cols="12">
          <v-img
            src="../assets/fireside.png"
            alt="Fireside Corner"
            class="rounded-xl"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="h3 HeaderUnderline">What is Fireside Corner?</p>
          <p class="h5">
            Fireside Corner is an eCommerce based website where users can
            purchase a variety of products including fireplaces, wood stoves,
            chimneys and soon installation and certification services.
          </p>
          <p class="h3 HeaderUnderline">The Background</p>

          <p class="h5">
            My father has been in the fireplace business for over 30 years
            including his own retail store called Fireside Corner. His slight
            obsession for fireplaces was imparted upon me at a young age. I grew
            up in a household with many fireplaces which we loved during
            Canadian winters. I decided to leverage my dads connections and
            expertise along with my experience in eCommerce and tech to create a
            new rendition to his old retail store.
          </p>
          <p class="h5">
            I decided to build a Shopify based eCommerce store that integrates
            directly with Facebook, Google Ads, and an integration with a
            localized shipping service to deliver products.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="h3 HeaderUnderline">What was your Role?</p>
          <p class="h5">
            Being the owner and operator of the business, I've been responsible
            for essentially everything. This includes sourcing products,
            managing inventory, marketing, ensuring delivery, and satisfaction
            of customers. I've also been responsible for the design and build of
            the website and all of the integrations working behind the scenes.
          </p>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Initiating creation and
            setup of the business itself.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Sourcing products and
            managing inventory levels.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Analyzing data, and setting
            deals based on seasonality and demand.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Managing marketing campaigns
            and direct sales and ensuring delivery of products.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Sourced delivery company and
            negotiated rates.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Building customer list to
            build lookalike audiences for Facebook and Google Ads.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="Section">
          <div class="align-items-centers">
            <div class="h3 d-flex justify-center mb-10">Let's Connect</div>
            <div class="h5 d-flex justify-center mb-10 centerText">
              Feel free to reach out.<br />
              I'm always open to discussing work, side projects, opportunities,
              or just to chat.<br />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <router-link to="Contact" class="empty">
          <div class="dot d-flex justify-center cursor">
            <v-icon class="CenterIcon" x-large dark>mail</v-icon>
          </div>
        </router-link>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Fireside",
  // data() {},
};
</script>
<style scoped>
.content-area {
  max-width: 800px;
}
.spacer {
  margin-bottom: 48px;
}
.h1 {
  font-size: 4.5em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
}
.h2 {
  font-size: 3em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}
.h3 {
  font-size: 2em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h5 {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-light {
  font-size: 1em;
  color: #edf6f9c4;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-bold {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}

.site-background {
  background-color: #edf6f9;
}
.dot {
  height: 130px;
  width: 130px;
  background-color: #ff6b6b;
  border-radius: 50%;
  display: flex;
}
.empty {
  color: transparent;
  text-decoration: none;
}

.cursor:hover {
  cursor: pointer;
}

.hero {
  background-color: black;
  min-height: 100vh;
}
.h2-white {
  font-size: 3em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}

.coolBlue {
  color: #4ecdc4;
}

.darkBlue {
  color: #1a535c;
}

.numbering {
  color: #ff6b6b;
  font-weight: 800;
}

.HeaderUnderline:after {
  content: "";
  display: block;
  width: 32px;
  padding-top: 3px;
  border-bottom: 2px solid #ff6b6b;
}
.SplashHeaderBackground {
  background-color: #edf6f9;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Section {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>

