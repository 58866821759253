<template class="pa-0">
  <div style="background: #1a535c" class="pa-0">
    <!-- Contact Form -->
    <v-container>
      <v-row class="py-12" justify="center">
        <v-col cols="12" lg="6" md="9" sm="6" xs="6" class="text-center">
          <div class="h1-light HeaderUnderline">Let's Connect.</div>
          <div class="h5-light mb-2 mt-10">Feel free to reach out.</div>
          <div class="h5-light mb-10">
            I'm always open to discussing work, side projects, opportunities, or
            just to chat.
          </div>

          <!-- Form -->
          <v-form ref="form" class="pb-15">
            <v-text-field
              class="mt-2 h4"
              background-color="#edf6f9"
              light
              filled
              flat
              label="Name"
              name="user_name"
            >
            </v-text-field>

            <v-text-field
              class="mt-2 h4"
              background-color="#edf6f9"
              light
              filled
              flat
              label="Company"
              name="user_company"
              id="user_company"
            >
            </v-text-field>

            <v-text-field
              class="mt-2 h4"
              background-color="#edf6f9"
              light
              filled
              flat
              label="Email"
              name="user_email"
              id="user_email"
            >
            </v-text-field>

            <v-textarea
              no-resize
              class="mt-2 h4"
              background-color="#edf6f9"
              light
              filled
              flat
              label="Message"
              name="user_message"
              id="user_message"
            >
            </v-textarea>

            <v-btn
              class="mt-5 h3"
              color="#ff6b6b"
              dark
              x-large
              depressed
              @click="sendEmail"
              >Submit</v-btn
            >
          </v-form>
          <!-- <form ref="form1"></form> -->
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-img
          class="full-width"
          src="../assets/Clipped-Background.svg"
        ></v-img>
      </v-row>
    </v-container>
  </div>
</template>
<script
src="https://smtpjs.com/v3/smtp.js">
</script>
<script>
import emailjs from "@emailjs/browser";
export default {
  name: "ContactForm",
  methods: {
    sendEmail() {
      console.log(this.$refs.form, this.$refs.form1);
      emailjs
        .sendForm(
          "contact_riley",
          "contact_riley",
          this.$refs.form.$el,
          "KpTV--bRhodQ6FuNp"
        )
        .then(
          (result) => {
            alert("Thank you! I will be in touch shortly.", result.text);
          },
          (error) => {
            alert("Oops! Something went wrong, please try again.", error.text);
          }
        );
    },
  },
};
</script>

<style>
.full-width {
  width: 100% !important;
}
</style>