<template>
  <div class="site-background">
    <v-container class="content-area">
      <v-row class="SplashHeaderBackground">
        <v-col cols="12">
          <v-img
            src="../assets/parallel.png"
            alt="Parallel Project"
            class="rounded-xl"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="h3 HeaderUnderline">What is Parallel?</p>
          <p class="h5">
            Parallel is a peer to peer shopping platform that leverages an AI
            technology we built that gives users extremely accurate body
            measurements with only two photos of themselves. Parallel solves the
            issue of both fit and style using a peer to peer based approach.
          </p>
          <p class="h3 HeaderUnderline">The Background</p>

          <p class="h5">
            Parallel was built to solve issues around clothing sizing. It help
            to explain how I and a team of developers came to the idea of
            Parallel by explaining the background. Parallel was conceived after
            building a previous product called "Kyper" that was a chrome
            extension that leveraged the same body measurement technology and
            told you whether the item in your cart would fit ot not. During the
            project, it was abundantly clear how using size guides would not
            solve the size problem.
          </p>
          <p class="h5">
            Instead of building a product on top of flawed size guides, I
            suggested we instead use the body measurement technology as the
            common denominator and leverage peer to peer data to inform users on
            what would fit. This is how Parallel was born.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="h3 HeaderUnderline">What was your Role?</p>
          <p class="h5">
            As the sole Product Manager, here are some of my responsibilities:
          </p>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Setting the product vision
            of Parallel for current and future development cycles.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Working with stakeholders to
            align the product vision through the organization.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Speaking with users to
            ensure what we build is useful to them.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Used various data analytics
            tools to inform decisions on the product.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Worked with 10+ developers
            and designer on execution and completed acceptance testing
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Broke down high level vision
            into actionable tasks and roadmap.
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col>
          <p class="h3 HeaderUnderline">Biggest Impacts</p>
          <p class="h5">
            Besides the initial product discovery and ideation of Parallel, here
            are some improvements I helped make on Parallel:
          </p>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Increased Average Engagement
            Time per Session by 28% through various gamification techniques.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Increased Sign Up Conversion
            by 31% over 3 months by A/B testing and simplifying sign up flow.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Increased User Retention by
            150% over 4 months by optimizing user flows for “Aha Moment”.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Introduced a highly
            successful referral program that became the primary user acquisition
            and growth method. &#40;81% of users&#41;
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Led the full product
            lifecycle on the following consumer facing features: consumer-facing
            website, iOS &#38; Android mobile application, chrome extension.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Led the full product
            lifecycle on the following internal systems: custom CRM, content
            moderation system, identity verification system using facial
            recognition and B2B API.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="Section">
          <div class="align-items-centers">
            <div class="h3 d-flex justify-center mb-10">Let's Connect</div>
            <div class="h5 d-flex justify-center mb-10 centerText">
              Feel free to reach out.<br />
              I'm always open to discussing work, side projects, opportunities,
              or just to chat.<br />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <router-link to="Contact" class="empty">
          <div class="dot d-flex justify-center cursor">
            <v-icon class="CenterIcon" x-large dark>mail</v-icon>
          </div>
        </router-link>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Parallel",
  // data() {},
};
</script>
<style scoped>
.content-area {
  max-width: 800px;
}
.spacer {
  margin-bottom: 48px;
}
.h1 {
  font-size: 4.5em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
}
.h2 {
  font-size: 3em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}
.h3 {
  font-size: 2em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h5 {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-light {
  font-size: 1em;
  color: #edf6f9c4;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-bold {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}

.site-background {
  background-color: #edf6f9;
}
.dot {
  height: 130px;
  width: 130px;
  background-color: #ff6b6b;
  border-radius: 50%;
  display: flex;
}
.empty {
  color: transparent;
  text-decoration: none;
}

.cursor:hover {
  cursor: pointer;
}

.hero {
  background-color: black;
  min-height: 100vh;
}
.h2-white {
  font-size: 3em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}

.coolBlue {
  color: #4ecdc4;
}

.darkBlue {
  color: #1a535c;
}

.numbering {
  color: #ff6b6b;
  font-weight: 800;
}

.HeaderUnderline:after {
  content: "";
  display: block;
  width: 32px;
  padding-top: 3px;
  border-bottom: 2px solid #ff6b6b;
}
.SplashHeaderBackground {
  background-color: #edf6f9;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Section {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>

