<template>
  <div class="site-background">
    <v-container class="content-area">
      <v-row class="SplashHeaderBackground">
        <v-col cols="12">
          <v-img
            src="../assets/wpgpropertyassessment.png"
            alt="home Assessment Project"
            class="rounded-xl"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="h3 HeaderUnderline">What is Winnipeg Home Assessment?</p>
          <p class="h5">
            winnipeghomeassessment.com is a website that helps home owners
            assess the value of their property. It utilizes a City of Winnipeg
            API for valuations.
          </p>
          <p class="h3 HeaderUnderline">The Background</p>

          <p class="h5">
            This website was built by request from a client who runs a home
            building company in the Winnipeg Metro Area. The client wanted a
            simple website that would help people find the publicly assessed
            value of their home while building up an email marketing list of
            potential future customers.
          </p>
          <p class="h5">
            The form takes in the address of the property and the email from a
            user and calls an API to display the value of the property to the
            user. The email and address of the user is then stored in a database
            for the client to access and utilize to market towards this person
            as a potential cue that the user is interested in selling their
            home.
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-15">
        <v-col>
          <p class="h3 HeaderUnderline">What was your Role?</p>
          <p class="h5">
            As the sole Software Developer, here were some of my
            responsibilities:
          </p>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Use HTML, CSS, and
            JavaScript to develop the front end of the website.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Integrated the Open API to
            send and receive data from the City of Winnipeg.
          </div>

          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Working with the client to
            understand their needs, and architect a solution that meets their
            needs.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Designing the style of the
            website with approval of the client.
          </div>
          <div class="h5-bold mb-1">
            <span class="numbering">&#x2022;</span> Set up a database to store
            the user information in an easily accessible way for the client.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="Section">
          <div class="align-items-centers">
            <div class="h3 d-flex justify-center mb-10">Let's Connect</div>
            <div class="h5 d-flex justify-center mb-10 centerText">
              Feel free to reach out.<br />
              I'm always open to discussing work, side projects, opportunities,
              or just to chat.<br />
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <router-link to="Contact" class="empty">
          <div class="dot d-flex justify-center cursor">
            <v-icon class="CenterIcon" x-large dark>mail</v-icon>
          </div>
        </router-link>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "homeassessment",
  // data() {},
};
</script>
<style scoped>
.content-area {
  max-width: 800px;
}
.spacer {
  margin-bottom: 48px;
}
.h1 {
  font-size: 4.5em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 800;
}
.h2 {
  font-size: 3em;
  color: #ff6b6b;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}
.h3 {
  font-size: 2em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}
.h5 {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-light {
  font-size: 1em;
  color: #edf6f9c4;
  font-family: "Raleway", "sans-serif";
  font-weight: 400;
}
.h5-bold {
  font-size: 1em;
  color: #1a535c;
  font-family: "Raleway", "sans-serif";
  font-weight: 600;
}

.site-background {
  background-color: #edf6f9;
}
.dot {
  height: 130px;
  width: 130px;
  background-color: #ff6b6b;
  border-radius: 50%;
  display: flex;
}
.empty {
  color: transparent;
  text-decoration: none;
}

.cursor:hover {
  cursor: pointer;
}

.hero {
  background-color: black;
  min-height: 100vh;
}
.h2-white {
  font-size: 3em;
  color: white;
  font-family: "Raleway", "sans-serif";
  font-weight: 700;
}

.coolBlue {
  color: #4ecdc4;
}

.darkBlue {
  color: #1a535c;
}

.numbering {
  color: #ff6b6b;
  font-weight: 800;
}

.HeaderUnderline:after {
  content: "";
  display: block;
  width: 32px;
  padding-top: 3px;
  border-bottom: 2px solid #ff6b6b;
}
.SplashHeaderBackground {
  background-color: #edf6f9;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Section {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>

