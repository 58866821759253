<template>
  <div>
    <template>
      <ContactForm></ContactForm>
    </template>
  </div>
</template>
<script>
import ContactForm from "/src/components/ContactForm.vue";
export default {
  name: "Contact",
  components: {
    ContactForm,
  },
};
</script>
<style>
</style>